import React, { Fragment, useReducer, useState } from 'react'
import '../../styles/pages/add-new.scss';
import Textfield from '../../components/Textfield';
import Button from '../../components/Button';
import Textarea from '../../components/Textarea';
import SelectButton from '../../components/SelectButton';
import Checkboxes from '../../components/Checkboxes';
import Select from '../../components/Select';

import { useNavigate, useParams, Link } from "react-router-dom";
import Header from '../../components/Header';

import { addToFirebase } from '../../helpers/addToFirebase';

const js = [
    {
        name: "Trainer/in",
    },
    {
        name: "J+S Grundausbildung",
    },
    {
        name: "J+S Weiterbildung 1",
    },
    {
        name: "J+S Weiterbildung 2 / Weiterbildung 2 Leistungssport / Expertenausbildung",
    },
    {
        name: "J+S Coach",
    },
    {
        name: "1418coach",
    },
    {
        name: "Esa-Leiter/in",
    },
    {
        name: "Esa-Expert/in",
    },
];


const sportart = [
    { name: "Aerobic" },
    { name: "Akrobatikturnen" },
    { name: "Fachtest" },
    { name: "Faustball" },
    { name: "Geräteturnen" },
    { name: "Gymnastik" },
    { name: "Korbball" },
    { name: "Kunstturnen Frauen" },
    { name: "Kunstturnen Männer" },
    { name: "Leichtathletik" },
    { name: "Nationalturnen" },
    { name: "Rhönrad" },
    { name: "Rhythmische Gymnastik" },
    { name: "Trampolin" },
    { name: "Turnen" },
    { name: "Unihockey" },
    { name: "Volleyball" }
];

const initialState = {
    vorname: '',
    nachname: '',
    email: '',
    organisation: '',
    titel: '',
    beschreibung: '',
    type: 'suche',
    adresse: '',
    plz: '',
    ort: '',
    termine: '',
    js_ausbildung: null,
    sportart: null,

}

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state, [action.key]: action.value,
            }
        case 'REPLACE':
            return action.payload;
        default:
            return state
    }
}

export default function AddNew() {


    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);


    const sendEmail = () => {
        fetch('https://ztv.dkueffer.cyon.site/api')
            .then(response => {
                if (!response.ok) {
                    console.error('Fetch error: ' + error);
                }
                return response.json(); // Assuming the response is in JSON format
            })
            .then(data => {
                // Handle the data here
                console.log(data);
            })
            .catch(error => {
                // Handle any errors that occurred during the fetch
                console.error('Fetch error: ' + error);
            });

    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        addToFirebase({ ...reducerState })
            .then((docId) => {
                console.log("Document added with ID:", docId);
                sendEmail();
                navigate('/success')
            })
            .catch((error) => {
                console.error("Error adding document:", error);
            });
    }


    return (
        <Fragment>
            <div className='add-new'>
                <h2>Eintrag erfassen</h2>

                <p>Möchtest du dich als Trainer/in, J+S-Leiter/in, 1418coach oder als esa-Leiter/in im Zürcher Turnsport engagieren? Oder sucht dein Verein noch Leiter/innen für ein Trainingslager oder ein/e Betreuer/in für ein regelmässiges Training? Auf dieser Plattform findest du eine Übersicht. Und bei Bedarf kannst du einen eigenen Eintrag erfassen.</p>
                <button className="button" onClick={() => navigate("/")}>Übersicht</button>
                <button className="button" onClick={() => navigate(process.env.PUBLIC_URL + "/neuer-eintrag")}>Neuer Eintrag</button>

                <h2>Erfassungsregeln:</h2>
                <p>
                    <b>«Ich biete»:</b> Hier kannst du als Trainer/in, J+S-Leiter/in, 1418coach, esa-Leiter/in oder als J+S-Coach einen Eintrag erstellen, wenn du gerne Trainings geben möchtest oder noch freie Zeit zur Verfügung hast.
                </p>
                <p>
                    <b>«Ich suche»:</b> Hier können alle Vereine, die im Zürcher Turnsport tätig sind, Inserate schalten, wenn Personal gesucht wird.
                </p>
                <form onSubmit={e => { handleSubmit(e) }}>
                    <SelectButton value={reducerState.type} dispatch={dispatch} ichSuche="ich suche" ichBiete="ich biete" />
                    <h2>Deine Kontaktdaten</h2>
                    <p>Fülle bitte zuerst deine Kontaktdaten aus, so können wir die Angebote der richtigen Person zuordnen. Mit dem Absenden des Formulars, nimmst du unsere <Link to="/datenschutz" style={{ color: "#0a82c5" }}>Datenschutzbestimmungen</Link> zur Kenntnis.</p>
                    <div className="columns-2">
                        <Textfield
                            value={reducerState.vorname}
                            onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'vorname',
                                })}
                            placeholder="Vorname" />
                        <Textfield
                            value={reducerState.nachname}
                            onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'nachname',
                                })}
                            placeholder="Nachname" />
                    </div>
                    <Textfield
                        value={reducerState.email}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'email',
                            })}
                        placeholder="Email" />
                    {reducerState.type == 'suche' ?
                        <div>
                            <Textfield
                                value={reducerState.organisation}
                                onChange={(e) =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: e,
                                        key: 'organisation',
                                    })}
                                placeholder="Organisation / Verein" />
                        </div> : null}

                    <Textfield
                        value={reducerState.adresse}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'adresse',
                            })}
                        placeholder="Adresse" />
                    <div className="columns-2">
                        <Textfield
                            value={reducerState.plz}
                            onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'plz',
                                })}
                            placeholder="PLZ" />
                        <Textfield
                            value={reducerState.ort}
                            onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'ort',
                                })}
                            placeholder="Ort" />
                    </div>
                    <h2>Dein Angebot</h2>
                    <p>Gib hier bitte Details zu deinem Angebot an, je genauer desto besser.</p>
                    <Textfield
                        value={reducerState.titel}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'titel',
                            })}
                        placeholder="Titel" />
                    <Textarea
                        value={reducerState.beschreibung}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'beschreibung',
                            })}
                        placeholder={reducerState.type === "suche" ? "Beschreibung inkl. Informationen zur Entschädigung" : "Beschreibung"}
                    />
                    <Textarea
                        value={reducerState.termine}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'termine',
                            })}
                        placeholder="Termine"
                    />
                    <h2>Ausbildung</h2>
                    <p>{reducerState.type === 'suche' ? "Wähle hier die Ausbildung aus, die du erwartest." : "Wähle hier bitte die Ausbildung an, die du absolviert hast."}</p>
                    <Checkboxes items={js} checkedState={reducerState.js_ausbildung} dispatch={dispatch} keyName="js_ausbildung" />
                    <h2>Sportart</h2>
                    <p>{reducerState.type === 'suche' ? "Wähle hier die Sportart aus, die du erwartest." : "Wähle hier bitte die Sportart, in der du die Ausbildung hast."}</p>

                    <Checkboxes items={sportart} checkedState={reducerState.sportart} dispatch={dispatch} keyName="sportart" />


                    <Button
                        type='submit'
                        value="Speichern"
                    />
                    {error ? <div className="error">{error.message + ": etwas ist schief gelaufen..."}</div> : null}
                    {success ? <div className="success">etwas ist schief gelaufen..."</div> : null}

                </form>
            </div>
        </Fragment>
    );
}
