import { collection, addDoc, serverTimestamp, doc, updateDoc } from "firebase/firestore";
import { db } from '../helpers/firebase';

const addToFirebase = (entry) => {
    return new Promise(async (resolve, reject) => {
        try {
            const entryWithDate = {
                ...entry,
                date_created: serverTimestamp()
            };


            const docRef = await addDoc(collection(db, "entries"), {
                entry: entryWithDate,
            });
            console.log("Document written with ID: ", docRef.id);
            resolve(docRef.id); // Resolve with the document ID
        } catch (e) {
            console.error("Error adding document: ", e);
            reject(e); // Reject with the error
        }
    });
};

const updateStatusInFirebase = (documentId, newValue) => {
    return new Promise(async (resolve, reject) => {
        try {
            const docRef = doc(db, "entries", documentId);

            // Create an object with the field you want to update
            const updateData = {
                status: newValue,
            };

            // Use updateDoc to update the specified field
            await updateDoc(docRef, updateData);

            console.log(`Field STATUS updated in document with ID: ${documentId}`);
            resolve(documentId); // Resolve with the document ID
        } catch (e) {
            console.error("Error updating field: ", e);
            reject(e); // Reject with the error
        }
    });
};

export { updateStatusInFirebase, addToFirebase };
