import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
    getAuth, signOut,
} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBbcYk-t1yEuzvJoPwLbOtD-g4zPcUoFfQ",
    authDomain: "zuerchertv-39fc1.firebaseapp.com",
    projectId: "zuerchertv-39fc1",
    storageBucket: "zuerchertv-39fc1.appspot.com",
    messagingSenderId: "221110249241",
    appId: "1:221110249241:web:56b7ab5063ee0862f84788"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, app, auth, signOut };