import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from '../helpers/firebase';

const readFromFirebase = (user) => {
    return new Promise(async (resolve, reject) => {
        try {
            let q;
            if (user) {
                q = query(collection(db, "entries"));

            } else {
                q = query(collection(db, "entries"), where("status", "==", "published"));

            }

            const querySnapshot = await getDocs(q); const newData = querySnapshot.docs
                .map((doc) => ({ ...doc.data(), id: doc.id }));
            resolve(newData);
        } catch (error) {
            reject(error);
        }
    });
};

export default readFromFirebase;
