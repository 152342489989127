
const Select = (props) => {
    const { value, onChange, options } = props;
    const handleChange = (e) => {
        e.preventDefault();
        onChange(e.target.value);
    }

    return <div className={'input-wrapper'}>
        <select value={value} onChange={handleChange}>
            {options.map(option => (
                <option key={option.value} value={option.value} defaultValue={option.selected} hidden={option.hidden}>
                    {option.text}
                </option>
            ))}
        </select></div>;
};

Select.defaultProps = {
    option: []
}


export default Select;