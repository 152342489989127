import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import Login from './containers/LoginForm/LoginForm';

import AddNew from './pages/addnew/AddNew';
import Boerse from './pages/boerse/Boerse';
import Success from './pages/sucess/Sucess';
import Admin from './pages/boerse/Admin';

import Footer from './components/Footer';
import Header from './components/Header';
import Datenschutz from './pages/datenschutz/Datenschutz';

function App() {
  const location = useLocation();
  return (
    <div className="App" location={location}>
      <Header />

      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<Boerse />} />
        <Route path="/admin" element={<Admin />} />

        <Route path="/neuer-eintrag" element={<AddNew />} />
        <Route path="/success" element={<Success />} />
        <Route path="/datenschutz" element={<Datenschutz />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
