import React, { useState, Fragment } from 'react'
import '../../styles/pages/boerse.scss';

import { useNavigate } from "react-router-dom";

import Header from '../../components/Header';

export default function Datenschutz() {
    const navigate = useNavigate();

    return (<Fragment>

        <div className='add-new'>

            <h2>Datenschutzbestimmungen</h2>

            <p>Alle von dir veröffentlichten Daten sind während acht Wochen auf der Jobbörse des Zürcher Turnverbandes öffentlich einsehbar. Die angegebenen Daten werden vom Zürcher Turnverband nicht zu anderen Zwecken verwendet und auch nicht an Dritte weitergegeben. Nach acht Wochen werden alle Angaben unwiderruflich gelöscht. </p>
            <button className="button" onClick={() => navigate('/')}>Übersicht</button>
            <button className="button" onClick={() => navigate('/neuer-eintrag')}>Neuer Eintrag</button>
        </div>
    </Fragment>
    )
}
