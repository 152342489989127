import React from 'react'
import '../styles/components/header.scss';

import { useParams } from 'react-router-dom';
export default function Header() {
    const { language } = useParams();

    return (
        <header className="header">
            <div className="header__content">
                <div><a href="https://ztv.ch" target="__blank"><img src={process.env.PUBLIC_URL + "/images/ZTV_Logo_250.png"} alt="zürcher turnverband logo" /></a></div>
                <div>Jobbörse</div>
            </div>
        </header>
    )
}
