import Textfield from '../../components/Textfield';
import Button from '../../components/Button';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../helpers/firebase';

import '../../styles/containers/login-form.scss';

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [err, setErr] = useState(null);

    const navigate = useNavigate();



    const handleSubmit = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                navigate("/admin")
                console.log(user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
            });

    }

    return <div className='login-form' style={{ marginBottom: 100 }}>
        <h1>Login</h1>
        <form onSubmit={e => { handleSubmit(e) }}>
            <Textfield value={email} onChange={setEmail} placeholder="Email" />
            <Textfield value={password} onChange={setPassword} placeholder="Passwort" type="password" />
            {err ? <div className="error-message">{err}</div> : null}

            <Button
                type='submit'
                value='Login'
            />
        </form>

    </div>;
};

export default LoginForm;