import React, { useState } from 'react';
import '../styles/components/checkboxes.scss';

export default function Checkboxes(props) {

    const { items, dispatch, keyName } = props;

    const [checkedState, setCheckedState] = useState(
        new Array(items.length).fill(false)
    );


    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        const values = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        var mask = updatedCheckedState.map(item => item);
        var newlist = items.filter((item, i) => mask[i]);
        console.log(newlist);
        dispatch({
            type: 'UPDATE',
            value: newlist,
            key: keyName,
        })
        setCheckedState(updatedCheckedState);
    }
    return (
        <ul className="ausbildung-list">
            {items.map(({ name, price }, index) => {
                return (
                    <li key={index}>
                        <div className="ausbildung-list-item">
                            <div className="left-section">
                                <input
                                    type="checkbox"
                                    id={`${keyName}-${index}`}
                                    name={name}
                                    value={name}
                                    checked={checkedState[index]}
                                    onChange={() => handleOnChange(index)}
                                />
                                <label htmlFor={`${keyName}-${index}`}>{name}</label>
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    )
}