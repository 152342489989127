import React from 'react'
import './footer-style.scss';

export default function Footer() {
    return (
        <footer className="footer" role="contentinfo">
            {/* 
            <div id="footer_menu">
                <ul className="nav menu nav-pills mod-list">
                    <li className="item-230">
                        <a href="/index.php/kontakt">Kontakt</a>
                    </li>
                    <li className="item-231">
                        <a href="/index.php/medien">Medien</a>
                    </li>
                    <li className="item-232">
                        <a href="/index.php/impressum">Impressum</a>
                    </li>
                    <li className="item-235">
                        <a href="/index.php/datenschutz">Datenschutz</a>
                    </li>
                    <li className="item-233 current active">
                        <a href="/index.php">© ZTV</a>
                    </li>
                    <li className="item-234">
                        <a
                            href="https://www.agimus-web.ch"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            @agimus-web.ch
                        </a>
                    </li>
                </ul>
            </div>
            */}
            <div className="container-fluid">
                <div className="custom sponsor haupt">
                    <h5>Hauptsponsorin</h5>
                    <div className="haupt">
                        <a
                            href="https://www.zkb.ch/"
                            target="_blank"
                            rel="noopener"
                            title="zur Hauptsponsorin ZKB"
                        >
                            <img
                                src="/images/zkb.png"
                                alt="Hauptsponsor Zürcher Kantonalbank"
                                width={317}
                                height={70}
                            />
                        </a>
                    </div>
                </div>
                <div className="custom sponsor partner">
                    <h5 style={{ textAlign: "left" }}>Sponsoren &amp; Partner</h5>
                    <div className="row-fluid">
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a
                                href="https://www.alder-eisenhut.ch/"
                                target="_blank"
                                rel="noopener"
                            >
                                <img
                                    src="/images/ae.png"
                                    alt="Partner Alder + Eisenhut"
                                />
                            </a>
                        </div>
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a href="https://www.balgrist.ch" target="_blank" rel="noopener">
                                <img
                                    src="/images/balgrist.png"
                                    alt="Partner balgrist"
                                    width={146}
                                    height={60}
                                />
                            </a>
                        </div>
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a href="https://www.bycello.ch" target="_blank" rel="noopener">
                                <img
                                    src="/images/bycello_lang.png"
                                    alt="bycello lang"
                                    width={146}
                                    height={60}
                                />
                            </a>
                        </div>
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a href="https://focuswater.ch/" target="_blank" rel="noopener">
                                <img
                                    src="/images/focuswater.png"
                                    alt="Partner Focus Water"
                                />
                            </a>
                        </div>
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a href="https://www.glarnerland.ch/" target="_blank" rel="noopener">
                                <img
                                    src="/images/glarnerland.png"
                                    alt="Partner Glarnerland"
                                />
                            </a>
                        </div>
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a href="https://www.markpro.ch/" target="_blank" rel="noopener">
                                <img src="/images/markpro.png" alt="Partner MarkPro" />
                            </a>
                        </div>
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a href="https://www.optimo-group.ch/" target="_blank" rel="noopener">
                                <img src="/images/optimo.png" alt="Partner Optimo" />
                            </a>
                        </div>
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a href="https://involve.ch/" target="_blank" rel="noopener">
                                <img src="/images/involve.jpg" alt="Partner Invole" />
                            </a>
                        </div>
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a href="https://www.rumox.ch" target="_blank" rel="noopener">
                                <img
                                    src="/images/rumox.png"
                                    alt="Partner Rumox"
                                    width={146}
                                    height={60}
                                />
                            </a>
                        </div>
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a href="https://www.special-a.ch/" target="_blank" rel="noopener">
                                <img
                                    src="/images/special_a.jpg"
                                    alt="Partner special a"
                                />
                            </a>
                        </div>
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a
                                href="https://sport.zh.ch/internet/sicherheitsdirektion/sport/de/home.html"
                                target="_blank"
                                rel="noopener"
                            >
                                <img
                                    src="/images/sportamt.png"
                                    alt="Partner Kanton Zürich Sportamt"
                                />
                            </a>
                        </div>
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a href="https://www.swisslos.ch/" target="_blank" rel="noopener">
                                <img src="/images/swisslos.jpg" alt="Partner Swisslos" />
                            </a>
                        </div>
                        <div className="span3" style={{ textAlign: "left" }}>
                            <a
                                href="https://www.visana.ch/de/privatkunden"
                                target="_blank"
                                rel="noopener"
                            >
                                <img src="/images/visana.png" alt="Partner Visana" />
                            </a>
                        </div>
                        <div className="span3">
                            <a href="https://www.zks-zuerich.ch/" target="_blank" rel="noopener">
                                <img
                                    src="/images/zks.png"
                                    alt="Partner ZKS"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="custom sponsor mitglieder">
                    <h5>Social Media</h5>
                    <div className="social">
                        <a
                            href="https://www.facebook.com/Turnverband.ZTV"
                            target="_blank"
                            rel="noopener"
                        >
                            <img
                                src="/images/f_eckig_1_100px_grau.png"
                                alt="f logo RGB Grey 72"
                                width={100}
                                height={100}
                            />
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UC8ZftVZgiOBwUPnq_eBZynA"
                            target="_blank"
                            rel="noopener"
                        >
                            <img
                                src="/images/yt_eckig_100px_grau.png"
                                alt="f logo RGB Grey 72"
                                width={100}
                                height={100}
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/ztv.turnverband/"
                            target="_blank"
                            rel="noopener"
                        >
                            <img
                                src="/images/in_eckig_100px_grau.png"
                                alt="f logo RGB Grey 72"
                                width={100}
                                height={100}
                            />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/ztv-z%C3%BCrcher-turnverband/"
                            target="_blank"
                            rel="noopener"
                        >
                            <img
                                src="/images/linkedin-icon.png"
                                alt="linkeding icon"
                                width={100}
                                height={100}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </footer>

    )
}
