import React, { useState, Fragment } from 'react'
import '../../styles/pages/boerse.scss';

import { useNavigate } from "react-router-dom";

import Header from '../../components/Header';

export default function Success() {
    const navigate = useNavigate();

    return (<Fragment>

        <div className='add-new'>

            <h2>Merci</h2>

            <p>Merci für den Eintrag, wir kontrollieren ihn und schalten ihn so bald wie möglich online.</p>
            <button className="button" onClick={() => navigate('/')}>Übersicht</button>
            <button className="button" onClick={() => navigate('neuer-eintrag')}>Neuer Eintrag</button>
        </div>
    </Fragment>
    )
}
