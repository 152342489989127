import React, { useEffect, useState, Fragment } from 'react'
import '../../styles/pages/boerse.scss';

import { useNavigate } from "react-router-dom";

import readFromFirebase from '../../helpers/readFromFirebase';
import { updateStatusInFirebase } from '../../helpers/addToFirebase';
import { signOut } from 'firebase/auth';

import { auth } from '../../helpers/firebase';
import { useAuthState } from "react-firebase-hooks/auth";


export default function Boerse() {

    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);


    const handleSignOut = async () => {
        try {
            await signOut(auth);
            // Sign-out successful
            // You can perform additional actions here if needed
        } catch (error) {
            console.error('Error signing out:', error);
            // Handle sign-out error
        }
    };


    const [items, setItems] = useState(null);

    const handleClick = async (item) => {
        console.log(item);
        const { status } = item;
        const newStatus = status === 'published' ? 'draft' : 'published';

        updateStatusInFirebase(item.id, newStatus)
            .then((docId) => {
                console.log("Document UPDATED with ID:", docId);
                readFromFirebase(user)
                    .then((data) => {
                        console.log(data);
                        const dataArray = data.map(item => ({ ...item.entry, id: item.id, status: item.status }));
                        dataArray.sort((a, b) => b.date_created?.seconds - a.date_created?.seconds);

                        setItems(dataArray);

                    })
                    .catch((error) => {
                        console.error("Error reading from Firebase:", error);
                    });
            })
            .catch((error) => {
                console.error("Error adding document:", error);
            });
    }

    useEffect(() => {



        readFromFirebase(user)
            .then((data) => {
                console.log(data);

                const eightWeeksAgo = new Date();
                eightWeeksAgo.setDate(eightWeeksAgo.getDate() - 8 * 7);

                const filteredData = data.filter(item => {
                    if (item.entry?.date_created && item.entry.date_created?.seconds) {
                        const entryDate = new Date(item.entry.date_created.seconds * 1000);
                        return entryDate >= eightWeeksAgo;
                    }
                    return false;
                });

                const dataArray = filteredData.map(item => ({ ...item.entry, id: item.id, status: item.status }));
                dataArray.sort((a, b) => b.date_created?.seconds - a.date_created?.seconds);



                setItems(dataArray);


            })
            .catch((error) => {
                console.error("Error reading from Firebase:", error);
            });

    }, [user])

    return (
        <Fragment>
            <div className='add-new'>
                <h2>Übersicht</h2>

                <p>
                    Möchtest du dich als Trainer/in, J+S-Leiter/in, 1418coach oder als esa-Leiter/in im Zürcher Turnsport engagieren? Oder sucht dein Verein noch Leiter/innen für ein Trainingslager oder ein/e Betreuer/in für ein regelmässiges Training? Auf dieser Plattform findest du eine Übersicht. Und bei Bedarf kannst du einen eigenen Eintrag erfassen.
                </p>
                <button className="button" onClick={() => navigate(process.env.PUBLIC_URL)}>Übersicht</button>
                <button className="button" onClick={() => navigate(process.env.PUBLIC_URL + "/neuer-eintrag")}>Neuer Eintrag</button>

                <h2>Erfassungsregeln:</h2>
                <p>
                    <b>«Ich biete»:</b> Hier kannst du als Trainer/in, J+S-Leiter/in, 1418coach, esa-Leiter/in oder als J+S-Coach einen Eintrag erstellen, wenn du gerne Trainings geben möchtest oder noch freie Zeit zur Verfügung hast.
                </p>
                <p>
                    <b>«Ich suche»:</b> Hier können alle Vereine, die im Zürcher Turnsport tätig sind, Inserate schalten, wenn Personal gesucht wird.
                </p>
                {items ? items.map((item) => {
                    if (item) {
                        const { vorname, nachname, email, titel, organisation, beschreibung, type, plz, ort, js_ausbildung, sportart, termine } = item;
                        const date = new Date(item.date_created?.seconds * 1000).toLocaleDateString('de-CH');
                        return (
                            <div className="boerse-item" key={item.id}>
                                {
                                    user !== null ?
                                        <div className="visibility-button" onClick={() => handleClick(item)}>{item.status === 'published' ? 'hide' : 'publish'}</div> : null

                                }
                                <div>
                                    <div className={"boerse-item__tag boerse-item__tag--" + type}>{type === "suche" ? "suche" : "biete"}</div><div className="boerse-item__tag boerse-item__tag--date">{date}</div>
                                    <h2>{titel}</h2>
                                </div>

                                <div className="boerse-item__organisation">{organisation !== "" ? organisation + ", " : null}{plz} {ort}</div>
                                <div>{beschreibung}</div>
                                {
                                    termine ?
                                        <div>
                                            <h3>Termine</h3>
                                            <div>{termine}</div>
                                        </div> : null
                                }

                                {
                                    js_ausbildung ? <div>
                                        <h3>Ausbildung</h3>
                                        <ul className="ausbildung">
                                            {js_ausbildung ? js_ausbildung.map((item, i) => <li key={i}>{item.name}</li>) : null}
                                        </ul>
                                    </div>
                                        : null
                                }
                                {
                                    sportart ? <div>
                                        <h3>Sportart</h3>
                                        <ul className="ausbildung">
                                            {sportart ? sportart.map((item, i) => <li key={i}>{item.name}</li>) : null}
                                        </ul>
                                    </div>
                                        : null
                                }
                                <h3>Kontakt</h3>
                                <div>{vorname} {nachname}</div>
                                <div><a href={"mailto:" + email}>{email}</a></div>

                            </div>)
                    }
                }
                ) : null}

            </div>
        </Fragment>
    )
}
